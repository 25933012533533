import ThemisInput from "@/components/shared/input"
import ThemisSpeakUpUsersFieldsValidationTable from "@/components/speak-up-users-fields-validation-table"
import ThemisValidationStatusIcons from "@/components/shared/validation-status-icons"
import { sortIndices } from "@/utils"
import csv from "csvtojson"
import {
  BOOLEAN,
  IMPORT_USERS_DOCUMENT_TYPE,
  SPEAK_UP_USERS_FIELDS_ITEMS,
  BULK_IMPORT_TYPES
} from "@/constants"
export default {
  name      : "BulkImportUsers",
  components: {
    ThemisInput,
    ThemisValidationStatusIcons,
    ThemisSpeakUpUsersFieldsValidationTable
  },
  props: {
  },
  emits: ["cancel", "updateBulkImportUsers"],
  data : () => ({
    currentImportUsersStep      : 1,
    firstRowAsHeader            : true,
    uploadedFile                : null,
    csvContent                  : null,
    headersArray                : [],
    validationSuccess           : false,
    validationFailure           : false,
    validationInProgress        : false,
    errorToBeDisplayed          : {},
    isStepTwoDisabled           : true,
    itemsForCsvDataValidation   : [],
    itemsForValidationTable     : [],
    speakUpUsersFieldsHeadersMap: new Map()
  }),
  computed: {
    getValidationRules() {
      return this.validationFailure ?
      `speakup_fields_error:true,${this.errorToBeDisplayed.message},${this.errorToBeDisplayed.sortedIndices}` : "speakup_fields_error:false"
    },
    firsRowAsHeaderLabelClass() {
      return this.uploadedFile ? "grey--text lighten-5" : "black--text body-2"
    },
    stepsForImportUsers() {
      return [{
        stepNumber: 1,
        label     : this.$t("2353")
      }, {
        stepNumber: 2,
        label     : this.$t("2354")
      }]
    },
    allowedImportUsersDocumentTypes() {
      return Object.values(IMPORT_USERS_DOCUMENT_TYPE).toString()
    },
    isNextStepDisabled() {
      if (this.currentImportUsersStep === 1) {
        return !this.uploadedFile || this.validationInProgress || !!Object.keys(this.errorToBeDisplayed).length
      }
      return false
    },
    isStartImportDisabled() {
      if (this.currentImportUsersStep === 2) {
        return this.isStepTwoDisabled
      }
      return false
    },
    isSpacerVisible() {
      return this.itemsForCsvDataValidation.length <= 5 || this.currentImportUsersStep === 1
    }
  },
  methods: {
    handleSpeakUpUsersFieldMappingStatus(item) {
      this.speakUpUsersFieldsHeadersMap.set(item.header, item)
      this.isStepTwoDisabled = this.validateStepTwo()
    },
    validateStepTwo() {
      if (!this.speakUpUsersFieldsHeadersMap) {
        return true
      }

      const mandatoryFields = [SPEAK_UP_USERS_FIELDS_ITEMS.EMAIL, SPEAK_UP_USERS_FIELDS_ITEMS.NAME]
      const itemValues      = Array.from(this.speakUpUsersFieldsHeadersMap.values())
      const hasNoError      = itemValues.every(value => value.error === BOOLEAN.FALSE)
      if (!hasNoError) {
        return true
      }

      const allFieldsPresent = mandatoryFields.every(field =>
        itemValues.some(value => value.mappedSpeakUpUsersField === field && value.error === BOOLEAN.FALSE)
      )
      return !allFieldsPresent
    },
    handleImportUsers() {
      const speakUpUsersFieldsMap     = Array.from(this.speakUpUsersFieldsHeadersMap.values())
      const speakUpUsersFieldsMapping = speakUpUsersFieldsMap
        .filter(
          speakUpUsersFieldMap =>
            speakUpUsersFieldMap.mappedSpeakUpUsersField !== SPEAK_UP_USERS_FIELDS_ITEMS.SKIP_IN_IMPORT
        )
        .map(speakUpUsersFieldMap => {
          const { mappedSpeakUpUsersField, header } = speakUpUsersFieldMap
          return {
            header,
            key: mappedSpeakUpUsersField
          }
        })
      this.$emit("updateBulkImportUsers", {
        type            : BULK_IMPORT_TYPES.USER,
        name            : this.uploadedFile.name,
        file            : this.uploadedFile,
        firstRowIsHeader: this.firstRowAsHeader,
        speakUpUsersFieldsMapping
      })
      this.resetChangesOfBulkImport()
    },
    restructureCsvDataForTable(jsonArray) {
      if (jsonArray.length > 0) {
        const headers = this.firstRowAsHeader ?
          this.headersArray : this.headersArray.map((_, index) => String(index + 1))

        const rows       = jsonArray.map(row => Object.values(row))
        const exampleRow =  rows[0]

        const transposedRows = rows[0].map((_, colIndex) => rows.map(row => row[colIndex]))

        return headers.map((header, index) => ({
          header,
          example         : exampleRow[index],
          headerColumnRows: transposedRows[index]
        }))

      } else {
        return []
      }
    },
    goToPreviousImportUsersStep() {
      this.currentImportUsersStep       = 1
      this.speakUpUsersFieldsHeadersMap = new Map()
    },
    goToNextImportUsersStep() {
      this.currentImportUsersStep    = 2
      this.itemsForCsvDataValidation = this.restructureCsvDataForTable(this.jsonArray)
    },
    resetChangesOfBulkImport() {
      this.firstRowAsHeader             = true
      this.currentImportUsersStep       = 1
      this.uploadedFile                 = null
      this.isStepTwoDisabled            = true
      this.validationSuccess            = false
      this.validationFailure            = false
      this.validationInProgress         = false
      this.headersArray                 = []
      this.speakUpUsersFieldsHeadersMap = new Map()
    },
    resetValidationStatus() {
      this.headersArray         = []
      this.validationSuccess    = false
      this.validationFailure    = false
      this.validationInProgress = false
      this.errorToBeDisplayed   = {}
    },
    cancelImportUsers() {
      this.resetChangesOfBulkImport()
      this.resetValidationStatus()
      this.$emit("cancel")
    },
    openFileExplorerToSelectImportUsersDocument() {
      this.$refs.input_users_csv_file.click()
    },
    async onImportUsersDocumentSelection(event) {
      this.validationInProgress = true
      this.uploadedFile         = event.target.files[0]
      this.csvContent           = await this.readFileAsText(this.uploadedFile)
      this.jsonArray            = await this.csvToJson(this.csvContent)

      if (this.firstRowAsHeader) {
        const emptyIndicesArray = this.getEmptyHeaderIndices(this.headersArray)
        if (emptyIndicesArray.length) {
          this.csvHeaderValidationFailure("2362", emptyIndicesArray)
          return
        }
        const duplicateHeaderIndices = this.getDuplicateHeaderIndices(this.headersArray)
        if (duplicateHeaderIndices.length) {
          this.csvHeaderValidationFailure("2363", duplicateHeaderIndices)
          return
        }
      } else {
        this.headersArray =  Object.keys(this.jsonArray[0])
      }
      const mismatchedIndices = this.validateCsvData(this.jsonArray)
      if (mismatchedIndices.length) {
        this.csvHeaderValidationFailure("2364", mismatchedIndices)
        return
      }
      this.csvHeaderValidationSuccess()
    },
    readFileAsText(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()

        reader.onload  = event => resolve(event.target.result)
        reader.onerror = event => reject(event) //file read error

        reader.readAsText(file)
      })
    },
    async csvToJson(csvContent) {
      const csvParser = csv({
        delimiter: "auto",
        noheader : !this.firstRowAsHeader
      })
      if (this.firstRowAsHeader) {
        csvParser.on("header", headers => {
          headers.forEach(header => {
            this.headersArray.push(header)
          })
        })
      }
      return await csvParser.fromString(csvContent)
    },
    getEmptyHeaderIndices(headers) {
      const indicesArray = []
      headers.forEach((header, index) => {
        if (header === "") {
          indicesArray.push(index + 1)
        }
      })
      return indicesArray
    },
    getDuplicateHeaderIndices(headers) {
      const indicesArray = []
      const headerSet    = new Set()
      headers.forEach((header, index) => {
        if (headerSet.has(header)) {
          indicesArray.push(index + 1)
        }
        headerSet.add(header)
      })
      return indicesArray
    },
    validateCsvData(jsonArray) {
      const indicesArray = []
      jsonArray.forEach((row, index) => {
        const rowKeys = Object.keys(row)
        if (rowKeys.length !== this.headersArray.length) {
          indicesArray.push(index + 1)
        }
      })
      return indicesArray
    },
    csvHeaderValidationFailure(message, indices) {
      this.validationFailure    = true
      this.validationInProgress = false
      this.validationSuccess    = false
      this.errorToBeDisplayed   = {
        message      : message,
        indicesLength: indices.length,
        sortedIndices: sortIndices(indices)
      }
    },
    csvHeaderValidationSuccess() {
      this.validationFailure    = false
      this.validationInProgress = false
      this.validationSuccess    = true
    },
    handleRemoveUploadedCsvFile() {
      this.resetValidationStatus()
      this.uploadedFile = null
    }
  }
}